import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	DocsTable,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { CodeBlock } from "../../../../../src/docs/docs-code";
import image from "../../../../../src/docs/example-img.png";
import { Markdown } from "./markdown";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Markdown",
	notepad: "",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Markdown"
			subTitle="Markdown simplifies text formatting with plain text syntax, promoting efficient writing and consistent content across platforms."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Markdown} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Markdown is a lightweight markup language that simplifies text formatting. It uses plain
					text syntax, making it easy to write and read. Markdown enhances content creation and
					ensures consistency across platforms.
				</Paragraph>
				<Paragraph>
					Markdown syntax is intuitive, offering a simple way to structure and style your text:
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Element</th>
							<th>Markdown Syntax</th>
							<th>Output Example</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Heading 1</td>
							<td>
								<Code># Heading 1</Code>
							</td>
							<td>
								<h1>Heading 1</h1>
							</td>
						</tr>
						<tr>
							<td>Heading 2</td>
							<td>
								<Code>## Heading 2</Code>
							</td>
							<td>
								<h2>Heading 2</h2>
							</td>
						</tr>
						<tr>
							<td>Paragraph</td>
							<td>Plain text</td>
							<td>This is a regular paragraph.</td>
						</tr>
						<tr>
							<td>Bold</td>
							<td>
								<Code>**bold text**</Code>
							</td>
							<td>
								<strong>bold text</strong>
							</td>
						</tr>
						<tr>
							<td>Italic</td>
							<td>
								<Code> *italic text*</Code>
							</td>
							<td>
								<em>italic text or italic text</em>
							</td>
						</tr>
						<tr>
							<td>Unordered list</td>
							<td>
								<Code>- Item</Code> or <Code>* Item</Code>
							</td>
							<td>
								<ul>
									<li>Item</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td>Ordered list</td>
							<td>
								<Code>1. Item</Code>
							</td>
							<td>
								<ol>
									<li>Item</li>
								</ol>
							</td>
						</tr>
						<tr>
							<td>Link</td>
							<td>
								<Code>[Link text](URL)</Code>
							</td>
							<td>
								<a href="https://www.siteimprove.com/">Link to Siteimprove</a>
							</td>
						</tr>
						<tr>
							<td>Image</td>
							<td>
								<Code>![Image alt text](folder/filename.png)</Code>
							</td>
							<td>
								<img src={image} alt="Image alt text" height="40" />
							</td>
						</tr>
						<tr>
							<td>Code</td>
							<td>
								<Code>`inline code`</Code> or <br />
								<CodeBlock language="markdown">{`
\`\`\`language
code block
with multiple lines
\`\`\`
`}</CodeBlock>
							</td>
							<td>
								<code>inline code</code>
								<br />
								<pre>
									<code>
										code block
										<br />
										with multiple lines
									</code>
								</pre>
							</td>
						</tr>
						<tr>
							<td>Blockquote</td>
							<td>
								<Code>{">"} quoted text</Code>
							</td>
							<td>
								<blockquote>
									<p>Blockquote</p>
								</blockquote>
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H3>Basic Usage</Header.H3>
				<Example fn={BasicUsage} />
				<Header.H3>HTML Escape</Header.H3>
				<Paragraph>
					Markdown generally handles HTML characters gracefully, but you can explicitly escape HTML
					tags if needed. Here's an example:
				</Paragraph>
				<Example fn={HTMLEscape} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Markdown}
					initialProps={{
						children: `This is a markdown string, it contains
- A short list
- with an *emphasized* word
- and a [link](https://www.siteimprove.com)`,
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Markdown</Code> when you need a simple, efficient way to format text content.
					</Paragraph>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Markdown</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Visuals/Empty State">
									Empty state with markdown
								</GatsbyLink>
								: Provide instructions or context.
							</>,
							"Content Authoring: Streamline the writing process.",
							"Documentation: Create clear, easy-to-follow guides.",
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Markdown</Code> to existing components for visual consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"You require complex layouts or precise formatting.",
							"Interactive elements or dynamic content are needed.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure Markdown content is structured logically with headings, lists, and appropriate emphasis.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Ul
						items={[<>This component comes with built-in accessibility, no extra work required.</>]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Keep Markdown content concise and focused.",
							"Avoid excessive inline styling or complex syntax.",
							"Use clear, descriptive link text.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/** @ignore [a11y:R79] */
const BasicUsage = () => {
	const md = `This is a markdown string, it contains
- A short list
- with an *emphasized* word
- and a [link](https://www.siteimprove.com)`;

	return (
		<>
			<p>The markdown string:</p>
			<pre>{md}</pre>
			<p>
				And the result when rendered via the <code>Markdown</code> component:
			</p>
			<Markdown>{md}</Markdown>
		</>
	);
};

/** @ignore [a11y:R79] */
const HTMLEscape = () => {
	const md = `
	> A quote

	a paragraph

	<ul><li>Some HTML</li><li>That makes a list</li></ul>
	`;

	return (
		<>
			<p>The markdown string:</p>
			<pre>{md}</pre>
			<p>
				And the result when rendered via the <code>Markdown</code> (with noEscape) component:
			</p>
			<Markdown noEscape>{md}</Markdown>
			<p>And without noEscape:</p>
			<Markdown>{md}</Markdown>
		</>
	);
};
